import { useMediaQuery } from '@react-hookz/web';
import classNames from 'classnames';
import { AdList } from 'components/ad-list/ad-list.component';
import { Pagination } from 'components/pagination/pagination.component';
import { User } from 'interfaces/user.interface';
import { useState } from 'react';
import themeVars from 'styles/theme.module.scss';
import style from './profile-page-ads.module.scss';
import { AdType, AdTypeLabels } from 'constants/ad-type.enum';
import { useUserStore } from 'stores';
interface ProfilePageAdsProps {
  user: User;
  type: AdType;
}

export const ProfilePageAds: React.FC<ProfilePageAdsProps> = ({ user, type }) => {
  const [page, setPage] = useState<number>(1);
  const ads = user.ads.filter((ad) => ad.type === type);
  const myUser = useUserStore((state) => state.user);
  const adsToShow = 10;

  const isMobile = useMediaQuery(`screen and (max-width:${themeVars['breakpoint-s']})`);

  const getHeader = () => {
    if (myUser && user.id === myUser.id) {
      return `Mina ${AdTypeLabels[type].toLowerCase()}`;
    } else if (RegExp(/[sS]/).test(user.publicName.substring(user.publicName.length - 1) || '')) {
      return `${user.publicName} ${AdTypeLabels[type].toLowerCase()}`;
    } else {
      return `${user.publicName}s ${AdTypeLabels[type].toLowerCase()}`;
    }
  };
  return (
    <>
      <h2 className={classNames(style.heading, 'heading-standard')}>{getHeader()}</h2>
      <AdList ads={ads.slice(isMobile ? 0 : (page - 1) * adsToShow, page * adsToShow)} />
      {ads.length > adsToShow && (
        <Pagination
          count={ads.length}
          page={page}
          perPage={adsToShow}
          onChangePage={setPage}
          className={style.bottommargin}
        />
      )}
    </>
  );
};
