import { FormikValues } from 'formik';
import { useNavigate } from 'react-router-dom';
import MultiStep from '../../components/forms/multi-step/multi-step.component';
import { StepComponent } from '../../interfaces/step-component.interface';
import { Main } from '../../layouts/main/main.component';
import { useUserStore } from '../../stores';
import { AccountInfo } from './components/account-info/account-info.component';
import { SelectAccountType } from './components/account-type/account-type.component';
import Conditions from './components/conditions/conditions.component';
import { SalesProfile } from './components/sales-profile/sales-profile.component';
import { updateUser } from 'services/user.service';

const BecomeSvippare = () => {
  const setUser = useUserStore((state) => state.setUser);
  const navigate = useNavigate();
  const steps: StepComponent[] = [SelectAccountType, AccountInfo, SalesProfile, Conditions];

  const initialValues = steps.reduce<Record<string, string | boolean | string[] | number | number[] | null>>(
    (values, step) => ({
      ...values,
      ...step.initialValues,
    }),
    {}
  );

  const submitForm = async (values: FormikValues) => {
    try {
      const updatedUser = await updateUser(values);
      if (updatedUser) {
        setUser(updatedUser);
        navigate('/myprofile');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Main>
      <MultiStep steps={steps} initialValues={initialValues} onSubmit={submitForm} lastStepText="Börja svippa!" />
    </Main>
  );
};

export default BecomeSvippare;
