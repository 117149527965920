import { Main } from 'layouts/main/main.component';
import { Spinner } from 'components/spinner/spinner.component';

const LoadingPage = () => {
  return (
    <Main>
      <Spinner size="large" />
    </Main>
  );
};

export default LoadingPage;
