import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import { LoginModal, RegistrationModal, DeleteModal} from 'components/modal';
import { useAdsStore } from 'services/ads.service';

import Auth from './components/authenticate/auth';
import { LandingPageView } from './views/landing-page/landing-page.component';
import BecomeSvippare from './views/become-svippare/become-svippare.component';
import LoadingPage from './views/loading-page/loading-page.component';
import LookAd from './views/look-ad/look-ad.component';
import { CreateAd } from './views/create-ad/create-ad.component';
import { MyProfilePage } from './views/my-profile-page/my-profile-page.component';
import { SearchAds } from './views/search-ads/search-ads.component';
import { SingleProfile } from 'views/single-profile/single-profile.component';

import { useEffect } from 'react';

function App() {
  const clearFilter = useAdsStore((state) => state.clearFilter);

  const handleRegistrationSuccess = (type: string) => {
    console.log(`Successfully registered ${type} user`);
  };

  useEffect(() => {
    clearFilter();
  }, [clearFilter]);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPageView />}></Route>
          <Route
            path="create-ad"
            element={
              <Auth>
                <CreateAd />
              </Auth>
            }
          ></Route>
          <Route
            path="become-svippare"
            element={
              <Auth>
                <BecomeSvippare />
              </Auth>
            }
          ></Route>

          <Route
            path="myprofile"
            element={
              <Auth>
                <MyProfilePage />
              </Auth>
            }
          ></Route>
          <Route path="search" element={<SearchAds />}></Route>
          <Route path="ads/:id" element={<LookAd />}></Route>
          <Route path="profile/:id" element={<SingleProfile />}></Route>
          <Route path="loading" element={<LoadingPage />}></Route>
        </Routes>

        <RegistrationModal onSuccess={handleRegistrationSuccess} />
        <LoginModal />
        <DeleteModal />
      </BrowserRouter>
    </>
    //<RegisterView/>
  );
}

export default App;
