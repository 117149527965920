import { useMediaQuery } from '@react-hookz/web';
import { Card } from 'components/card/card.component';
import { Checkbox } from 'components/forms/checkbox/checkbox.component';
import { FormField } from 'components/forms/form-field';
import styleFromFormField from 'components/forms/form-field/form-field.module.scss';
import { Column, Grid } from 'components/grid/grid.component';
import { AdType } from 'constants/ad-type.enum';
import { DeadlineType, DeadlineTypeLabels } from 'constants/deadline-type.enum';
import { ErrorMessage, useFormikContext } from 'formik';
import { StepComponent } from 'interfaces/step-component.interface';
import React, { useEffect } from 'react';
import { useCategories } from 'services/categories.service';
import { useLocations } from 'services/locations.service';
import { useUserStore } from 'stores';
import * as Yup from 'yup';
import theme from 'styles/theme.module.scss';

const AdInfo: StepComponent = () => {
  const { values, setFieldValue } = useFormikContext<{
    categories: number[];
    type: AdType;
    deadlineType: DeadlineType;
  }>();

  const { data: locations } = useLocations();
  const { data: categories } = useCategories();
  const isSScreen = useMediaQuery(`(max-width:${theme['breakpoint-s']})`);

  const user = useUserStore((state) => state.user);

  const isDateSelected = values.deadlineType === DeadlineType.Date;

  useEffect(() => {
    if (user?.placeId) {
      setFieldValue('placeId', user.placeId);
    }
  }, [setFieldValue, user?.placeId]);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    const isChecked = e.target.checked;

    const newCategories = [...values.categories];
    if (isChecked) {
      newCategories.push(value);
    } else {
      const index = newCategories.indexOf(value);
      if (index > -1) {
        newCategories.splice(index, 1);
      }
    }

    setFieldValue('categories', newCategories);
  };

  return (
    <Grid>
      <Column span={[8, 8, 12, 4]}>
        <Card>
          <h2 className="heading-small text-blue-dark">Beskrivning</h2>
          <FormField
            fieldSize="large"
            type="text"
            id="headline"
            name="headline"
            label="Rubrik"
            placeholder={values.type === AdType.Service ? "Jag gräver gropar..." : "Jag behöver flytthjälp..."}
            required
          />
          <FormField
            fieldSize="large"
            type="textarea"
            label="Beskrivning"
            id="description"
            name="description"
            placeholder={values.type === AdType.Service ? "Jag är expert på att gräva gropar." : "Min soffa behöver flyttas upp för en trappa."}
            rows={10}
          />
        </Card>
      </Column>
      <Column span={[4, 4, 12, 4]}>
        <Grid>
          <Column span={12}>
            <Card>
              <h2 className="heading-small text-blue-dark">Detaljer</h2>
              <FormField
                fieldSize="large"
                type="select"
                label="Plats"
                id="placeId"
                name="placeId"
                placeholder="Välj plats..."
              >
                {locations.map((location, index) => (
                  <option key={`loc-${location.id}-${index}`} value={location.id}>
                    {location.label}
                  </option>
                ))}
              </FormField>

              {values.type === AdType.Request && (
                <>
                  <FormField fieldSize="large" label="Deadline*" type="select" id="deadlineType" name="deadlineType">
                    {Object.values(DeadlineType).map((dlType, index) => (
                      <option key={`dlType-${index}-${dlType}`} value={dlType}>
                        {DeadlineTypeLabels[dlType]}
                      </option>
                    ))}
                  </FormField>
                  <FormField
                    fieldSize="large"
                    type="date"
                    id="deadline"
                    name="deadline"
                    label="Deadline datum"
                    placeholder="Hjälp mig gräva en grop..."
                    disabled={!isDateSelected}
                    required
                    style={!isDateSelected ? { color: 'grey' } : {}}
                  />
                </>
              )}
            </Card>
          </Column>
          <Column span={12}>
            <Card>
              <fieldset id="categories" aria-describedby="categories-label" className={isSScreen ? 'large' : ''}>
                <legend id="categories-label" className="heading-small text-blue-dark">
                  Kategorier
                </legend>
                {categories.map((category, index) => (
                  <span key={`cat-${index}-${category.id}`}>
                    <Checkbox
                      id={category.id.toString()}
                      label={category.label}
                      value={category.id.toString()}
                      checked={values.categories.includes(category.id)}
                      onChange={handleCheckboxChange}
                    />
                  </span>
                ))}
                <ErrorMessage name="categories">
                  {(error: string) => (
                    <div className={`${styleFromFormField.error}`}>
                      <img src="/static/media/icon-error.9793803858ddef6d644ee645640bde7e.svg" alt="" />
                      {error}
                    </div>
                  )}
                </ErrorMessage>
              </fieldset>
            </Card>
          </Column>
        </Grid>
      </Column>
    </Grid>
  );
};

AdInfo.title = 'Information om din tjänst';
AdInfo.label = 'Info';

AdInfo.initialValues = {
  headline: '',
  description: '',
  placeId: '',
  categories: [],
  deadlineType: DeadlineType.Asap,
  deadline: '',
};

AdInfo.validationSchema = Yup.object().shape({
  headline: Yup.string().required('En rubrik behöver du fylla i.'),
  description: Yup.string().required('Du måste beskriva tjänsten'),
  placeId: Yup.number().required('En plats behöver du fylla i.'),
  categories: Yup.array()
    .of(Yup.number())
    .test('minst_en', 'Minst en kategori måste väljas', (categories) => {
      return categories && categories?.length > 0;
    }),
  deadlineType: Yup.string()
    .oneOf(Object.values(DeadlineType))
    .when('type', (type, schema) => {
      if (type[0] === AdType.Request) {
        return schema.required('En deadline behöver du fylla i.');
      }
      return schema;
    }),
  deadline: Yup.string().test('deadlineDate', 'Deadline datum behöver du fylla i.', function (value) {
    const { deadlineType, type } = this.parent;
    if (type === AdType.Request && deadlineType === DeadlineType.Date) {
      return value != null && value !== '';
    }
    return true;
  }),
});

export default AdInfo;
