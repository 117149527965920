import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import classNames from 'classnames';
import { HTMLProps, ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';

import { updateUser } from 'services/user.service';
import { User } from '../../interfaces/user.interface';
import { useUserStore } from '../../stores';
import { Card } from '../card/card.component';
import { EditProfile } from './edit-profile';
import style from './profile.module.scss';
import { AccountType } from 'constants/account-type.enum';

import profileSrc from '../../assets/illustrations/avatar.png';
import { ReactComponent as EditIcon } from '../../assets/icons/icon-edit.svg';
import uflogo from '../../assets/logos/UF-logo.png';

interface ProfileProps extends HTMLProps<HTMLDivElement> {
  user: User;
  profilePage: Boolean;
  children?: ReactNode;
  allowEdit?: boolean;
}

const Profile = (props: ProfileProps) => {
  const { user, children, allowEdit = false } = props;
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const myUser = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);
  const canEdit = myUser && myUser.id === user.id;

  const handleImgChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      updateUser({ id: myUser?.id, headline: myUser?.headline, presentation: myUser?.presentation, avatar: file }).then(
        (res) => {
          if (res) {
            setUser(res);
          }
        }
      );
    }
  };

  const createdAt = new Date(user.createdAt);
  const memberSince = `${createdAt
    .toLocaleString('sv-SE', { month: 'short' })
    .replace('.', '')} ${createdAt.toLocaleString('sv-SE', {
    year: 'numeric',
  })}`;

  return isEdit ? (
    <EditProfile onEditEnd={() => setIsEdit(false)} />
  ) : (
    <>
      <Card className={style.container}>
        <div className={style.centerBox}>
          <div className={style.profileImgBox}>
            <img src={user?.avatar?.fullUrl || profileSrc} alt="" className={style.profileImg} />

            {canEdit && allowEdit && (
              <>
                <input
                  type="file"
                  id="profileImage"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={handleImgChange}
                />
                <label htmlFor="profileImage" className={`button bullet darkblue inverted ${style.profileImgButton}`}>
                  <AddAPhotoOutlinedIcon />
                </label>
              </>
            )}
          </div>
        </div>

        <div className={style.textBox}>
          <h2 className="heading-standard">
            <span>{user?.publicName}</span>
            {canEdit && allowEdit && (
              <button className={`bullet inverted darkblue ${style.editButton}`} onClick={() => setIsEdit(true)}>
                <EditIcon />
              </button>
            )}
          </h2>

          <p className={classNames('text-large', user?.description ? '' : style.placeholderText)}>
            {user?.description || (canEdit ? 'Beskriv dig själv' : '')}
          </p>
        </div>

        <hr />

        <ul className={style.infolist}>
          {props.profilePage && user?.answerEmail && (
            <li>
              <span>Svarsaddress</span>
              <a className="bold" href={`mailto:${user?.answerEmail}`}>
                {user?.answerEmail}
              </a>
            </li>
          )}
          {props.profilePage && user?.answerPhone && (
            <li>
              <span>Telefonnummer</span>
              <a className="bold" href={`tel:${user?.answerPhone}`}>
                {user?.answerPhone}
              </a>
            </li>
          )}
          {user?.place && (
            <li>
              <span>Från</span>
              <span className="bold">{user?.place.label}</span>
            </li>
          )}
          <li>
            <span> Medlem sedan</span>
            <span className="bold">{memberSince}</span>
          </li>
        </ul>
        {children && (
          <>
            <hr />
            {children}
          </>
        )}
      </Card>

      {props.profilePage && user?.type === AccountType.UFCompany && (
        <Card className={style.container} id={style.association}>
          <img src={uflogo} alt="UF Logo" />
          <hr />
          <h6>{user?.publicName} är ett UF företag</h6>
          <p>
            Beskrivning av hur {user?.publicName} använder UF-vid hans verksamhet och annan relevant information om {user?.publicName} UF företag.
          </p>
          <Link to="https://ungforetagsamhet.se" className="button outlined darkblue" target="_blank">
            Läs mer
          </Link>
        </Card>
      )}
    </>
  );
};

export { Profile };
