import { ErrorMessage, useFormikContext } from 'formik';
import { HTMLProps, useEffect } from 'react';
import * as Yup from 'yup';
import cameraImage from 'assets/illustrations/Profilbild.png';
import { Card } from 'components/card/card.component';
import { FormField } from 'components/forms/form-field';
import { useUserStore } from 'stores';
import style from './sales-profile.module.scss';
import { isValidFile } from 'utils/fileValidation';
import { getFileUrl } from 'utils/get-local-url.util';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import { Column, Grid } from 'components/grid/grid.component';
import styleFromFormField from 'components/forms/form-field/form-field.module.scss';
import ErrorIcon from '@mui/icons-material/Error';

interface RequestAddImgProps extends HTMLProps<HTMLDivElement> {
  avatar: File;
}
const SalesProfile = () => {
  const { setFieldValue, values } = useFormikContext<RequestAddImgProps>();
  const { user } = useUserStore();

  useEffect(() => {
    if (user) {
      setFieldValue('publicName', user?.publicName || `${user?.firstName}${user?.lastName}`, false);
      setFieldValue('description', user?.description || '', false);
      setFieldValue('headline', user?.headline || '', false);
      setFieldValue('presentation', user?.presentation || '', false);
    }
  }, [setFieldValue, user]);

  const handleImgChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFieldValue('avatar', e.target.files[0]);
    }
  };

  return (
    <Grid>
      <Column span={[4, 4, 12, 4]}>
        <Card>
          <label className={style.profileImgBox} htmlFor="upload-image">
            {user?.avatar || values.avatar ? (
              <img
                src={values.avatar ? getFileUrl(values.avatar) : user?.avatar?.fullUrl}
                alt=""
                className={style.profileImg}
              />
            ) : (
              <img src={cameraImage} alt="" />
            )}
            <input
              type="file"
              onChange={handleImgChange}
              id="upload-image"
              style={{ display: 'none' }}
              accept="image/*"
              name="avatar"
            />
            {(user?.avatar || values.avatar) && (
              <div className={`button bullet darkblue inverted ${style.profileImgButton}`}>
                <AddAPhotoOutlinedIcon />
              </div>
            )}
          </label>
          <ErrorMessage name="avatar">
            {(error: string) => (
              <div className={styleFromFormField.error}>
                <ErrorIcon />
                {error}
              </div>
            )}
          </ErrorMessage>
          <FormField
            fieldSize="large"
            type="text"
            placeholder="Katten med hatten"
            label="Visningsnamn*"
            name="publicName"
            id={'publicName'}
            required
          />

          <FormField
            fieldSize="large"
            type="text"
            name="description"
            id="description"
            label="Beskrivning*"
            placeholder="En slagkraftig mening"
            required
            rows={10}
          />
        </Card>
      </Column>
      <Column span={[8, 8, 12, 4]}>
        <Card>
          <h2 className="heading-small text-blue-dark">Presentation</h2>
          <FormField
            fieldSize="large"
            label="Rubrik*"
            placeholder="Här är jag..."
            type="text"
            name="headline"
            id={'headline'}
            required
          />
          <div>
            <FormField
              fieldSize="large"
              label="Presentationstext*"
              type="textarea"
              placeholder="Berätta något intressant om dig själv..."
              id="presentation"
              name="presentation"
              disabled={false}
              rows={10}
              required
            />
          </div>
        </Card>
      </Column>
    </Grid>
  );
};
SalesProfile.title = 'Skapa en försäljningsprofil';
SalesProfile.initialValues = {
  avatar: null,
  publicName: '',
  description: '',
  headline: '',
  presentation: '',
};
SalesProfile.validationSchema = Yup.object().shape({
  avatar: Yup.mixed()
    .test('is-valid-type', 'Felaktig filtyp', (file: any) => {
      if (!file) {
        return true;
      } else {
        return file && file.name && isValidFile(file.name.toLowerCase(), 'image');
      }
    })
    .test('file-size', 'Bilden är för stor', (file: any) => {
      if (!file) {
        return true;
      } else {
        return file.size <= 6291456; // 6 MB max size (1024*1024*6)
      }
    })
    .nullable(),
  publicName: Yup.string().required('Visningsnamn krävs'),
  description: Yup.string().required('Beskrivning krävs'),
  headline: Yup.string().required('Rubrik krävs'),
  presentation: Yup.string().required('Presentation krävs'),
});
export { SalesProfile };
