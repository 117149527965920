import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import classNames from 'classnames';
import { Formik, FormikProps } from 'formik';
import { HTMLProps, useState } from 'react';
import * as Yup from 'yup';

import profileSrc from 'assets/illustrations/avatar.png';
import { updateUser } from 'services/user.service';
import { getFileUrl } from 'utils/get-local-url.util';
import { useUserStore } from '../../../stores/user.store';
import { Card } from '../../card/card.component';
import { FormField } from '../../forms';
import profileStyle from '../profile.module.scss';
import style from './edit-profile.module.scss';
interface EditProfileProps extends HTMLProps<HTMLDivElement> {
  onEditEnd: () => void;
}

const EditProfile = (props: EditProfileProps) => {
  const { className, onEditEnd } = props;
  const [user, setUser] = useUserStore((state) => [state.user, state.setUser]);

  const [userUploadedImg, setUserUploadedImg] = useState<File | null>(null);

  const handleImgChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setUserUploadedImg(e.target.files[0]);
    }
  };

  const handleSubmit = async (values: { publicName: string; description: string, answerEmail: string, answerPhone: string }) => {
    try {
      const allValues = userUploadedImg ? { ...values, avatar: userUploadedImg } : values;
      const updatedUser = await updateUser(allValues);
      if (updatedUser) {
        setUser({ ...updatedUser });
        onEditEnd();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createdAt = new Date(user?.createdAt || '');
  const memberSince = `${createdAt
    .toLocaleString('sv-SE', { month: 'short' })
    .replace('.', '')} ${createdAt.toLocaleString('sv-SE', {
    year: 'numeric',
  })}`;

  return (
    <Formik
      initialValues={{ publicName: user?.publicName || '', description: user?.description || '', answerEmail: user?.answerEmail || '', answerPhone: user?.answerPhone || '', }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnChange
    >
      {(formikProps: FormikProps<{ publicName: string; description: string, answerEmail: string, answerPhone: string }>) => {
        const { handleSubmit, handleChange } = formikProps;
        return (
          <Card className={classNames(profileStyle.container, className)}>
            <div className={profileStyle.centerBox}>
              <div className={profileStyle.profileImgBox}>
                <img
                  src={userUploadedImg ? getFileUrl(userUploadedImg) : user?.avatar?.fullUrl || profileSrc}
                  alt="Profile"
                  className={profileStyle.profileImg}
                />

                <input
                  type="file"
                  id="editProfileImage"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={handleImgChange}
                />

                <label
                  htmlFor="editProfileImage"
                  className={`button bullet darkblue inverted ${style.profileImgButton}`}
                >
                  <AddAPhotoOutlinedIcon />
                </label>
              </div>
            </div>

            <div className={style.textBox}>
              <FormField fieldSize="large" type="text" name="publicName" label="Visningsnamn" onChange={handleChange}/>
              <FormField fieldSize="large" type="textarea" name="description" label="Beskrivning" onChange={handleChange}/>
              <FormField fieldSize="large" type="text" name="answerEmail" label="Svarsaddress" onChange={handleChange}/>
              <FormField fieldSize="large" type="text" name="answerPhone" label="Telefonnummer" onChange={handleChange}/>
            </div>
            <button type="submit" className="primary fullWidth" onClick={() => handleSubmit()}>
              Spara ändringar
            </button>

            <hr />

            <ul className={profileStyle.infolist}>
              {user?.place && (
                <li>
                  <span>Från</span>
                  <span className="bold">{user?.place.label}</span>
                </li>
              )}
              <li>
                <span> Medlem sedan</span>
                <span className="bold">{memberSince}</span>
              </li>
            </ul>
          </Card>
        );
      }}
    </Formik>
  );
};

const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
const validationSchema = Yup.object().shape({
  publicName: Yup.string().required('Du måste ange ett visningsnamn'),
  description: Yup.string(),
  answerEmail: Yup.string().email('Ogiltlig E-postaddress'),
  answerPhone: Yup.string().matches(phoneRegExp, 'Ogiltigt telefonnummer'),
});

export { EditProfile };
