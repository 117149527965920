import { ErrorMessage, Field, FieldProps } from 'formik';
import * as Yup from 'yup';
import { Card } from 'components/card/card.component';
import { Checkbox } from 'components/forms/checkbox/checkbox.component';
import styleFromFormField from 'components/forms/form-field/form-field.module.scss';
import { Typography } from '@mui/material';

const Conditions = () => {
  return (
    <Card>
      <h2 className="heading-small text-blue-dark">Våra villkor</h2> {/* TODO:!!! hämta db */}
      <Typography id=""> {/* FIX: styling + scrollpane???!!! */}
        1. Inledning <br/>
        &nbsp;&nbsp; 1.1 Svippo AB, org nr 559385–9928 (”Svippo”), är ett självständigt företag som tillhandahåller en plattform där privatpersoner och företag kan beställa eller utföra olika typer av tjänster. <br/>
        &nbsp;&nbsp; 1.2 De allmänna villkoren omfattar alla besökare och användare av tjänsten Svippo via plattformen. Genom att använda tjänsten godkänner du villkoren och ansvarar för att följa dem. Vänligen läs igenom villkoren noga och skriv gärna ut dem för framtida referens. <br/>
        &nbsp;&nbsp; 1.3 Svippo kan ändra de allmänna villkoren och kommer då att publicera den nya versionen på plattformen. De uppdaterade villkoren gäller för nya användare från och med att de publicerats och för befintliga användare 30 dagar efter att de publicerats på webbplatsen eller i appen. <br/>
        <br/>
        2. Användning av tjänsten <br/>
        &nbsp;&nbsp; 2.1 Så här registrerar du dig som användare. Du skapar ett konto gratis genom att registrera dig med din e-postadress och ett lösenord. Vid registrering klassas du som en beställare och kan inte utföra uppdrag. För att kunna utföra uppdrag behöver du registrera dig igen som Svippare, där vi kommevr göra en bakgrundscheck på dig eller ditt bolag. <br/>
        &nbsp;&nbsp; 2.2 För att registrera dig som användare måste du vara minst 16 år gammal. Om du är under 18 år krävs målsmans godkännande. Användare måste ha rättskapacitet att ingå avtal. Du kan inte acceptera eller utföra tjänster som kräver specifika licenser eller certifikat, till exempel VVS eller el, om du inte innehar rätt licens eller certifikat. När du har registrerat dig som användare accepterar du och är bunden av att följa villkoren. <br/>
        &nbsp;&nbsp; 2.3 Vid registrering måste varje ny användare registrera sig med sin e-postadress och skapa ett lösenord. Om det är ett företag som registrerar sig som användare ska en behörig person från företaget ansvara för registreringen. Privatpersoner som utför tjänster måste använda sig av en fakturera-utan-företagstjänst. <br/>
        &nbsp;&nbsp; 2.4 Svippo ansvarar inte för förmedlat uppdrag eller utfört uppdrag. Svippo tillhandahåller endast den plattform där uppdragstagare och uppdragsgivare kommer i kontakt med varandra. Som användare av Svippo kan du aldrig ställa Svippo till svars för något. <br/>
        &nbsp;&nbsp; 2.5 Som användare ansvarar du för att du inte publicerar något som strider mot villkoren eller mot aktuell lagstiftning. <br/>
        &nbsp;&nbsp; 2.6 Svippo kan inte ta ansvar för om det sker något avbrott i tjänsten eller andra störningar. <br/>
        <br/>
        3. Immateriella rättigheter <br/>
        &nbsp;&nbsp; 3.1 Användare behåller äganderätten till allt innehåll som de laddar upp på plattformen. Genom att ladda upp innehåll ger användaren Svippo en icke-exklusiv, global, royaltyfri licens att använda, distribuera och modifiera innehållet i syfte att tillhandahålla och marknadsföra tjänsten. Användare ansvarar för att det innehåll som laddas upp inte gör intrång i tredje parts immateriella rättigheter. Intrång i andras immateriella rättigheter kan medföra ansvar enligt lag. <br/>
        <br/>
        4. Den registrerades ansvar <br/>
        &nbsp;&nbsp; 4.1 Bara den som registrerat sig får använda kontot. Kan inte låna någon annans eller överlåta till någon annan. <br/>
        &nbsp;&nbsp; 4.2 Om en användare publicerar olagligt eller irrelevant innehåll på plattformen har Svippo rätt att omedelbart stänga av användarens konto och ta bort innehållet. Svippo avgör ensidigt vad som anses vara olagligt eller irrelevant innehåll och användaren kan inte klaga på beslutet. <br/>
        <br/>
        5. Felaktigt användande <br/>
        &nbsp;&nbsp; 5.1 Om en användare bryter mot uppställda villkor, avtal eller tillämplig lagstiftning i samband med användandet av Svippo kan åtgärder vidtas. Svippo har rätt att stänga av användares konto, radera meddelanden, ta bort annonser och på annat lämpligt sätt hantera användarens konto. Svippo har även rätt att stänga av personen eller företaget på så lång tid som Svippo anser lämpligt. Svippo ska meddela användaren på den e-postadress som angetts vid registrering om vidtagna åtgärder. Svippo avgör ensam vilka åtgärder som anses lämpliga. Användaren kan inte klaga på Svippos beslut. <br/>
        &nbsp;&nbsp; 5.2 Svippo tillhandahåller endast plattformen för att uppdragsgivare och uppdragstagare ska kunna komma i kontakt med varandra. Svippo ansvarar inte för fakturering eller betalning som sker direkt mellan två aktörer. Användare får inte använda plattformen för att tvätta pengar eller delta i någon form av olaglig finansiell verksamhet. Om Svippo misstänker att en användare är inblandad i penningtvätt eller annan olaglig finansiell verksamhet, förbehåller vi oss rätten att omedelbart stänga av användarens konto och rapportera misstänkt aktivitet till relevant myndighet. <br/>
        &nbsp;&nbsp; 5.3 För det fall vi finner att något som du lagt upp strider mot gällande lagstiftning eller på annat sätt kan medföra skada för oss eller tredje man, förbehåller vi oss rätten att radera detta och/eller stänga ditt användarkonto. Vi avgör ensidigt om så ska ske. Användare accepterar att andra Användare betygssätter denne genom Tjänsten. <br/>
        <br/>
        6. Arbeten med speciella förutsättningar <br/>
        &nbsp;&nbsp; 6.1 Uppdragstagare som ska ta hand om avfall, återvinning eller liknande ansvarar för att hantering av detta sker enligt gällande lagar och förordningar. Svippo kan inte på något sätt avkrävas ansvar för hantering av detta. <br/>
        &nbsp;&nbsp; 6.2 För att en uppdragstagare ska kunna utföra uppdrag som kräver visst certifikat eller utbildning, måste detta certifikat eller bevis på utbildning kunna visas upp på förfrågan. Varje användare av Svippo ansvarar för att se till att nödvändiga certifikat eller bevis om utbildning finns. Svippo kan inte på något sätt avkrävas ansvar för hantering av detta. <br/>
        <br/>
        7. Skatt och mervärdesskatt <br/>
        &nbsp;&nbsp; 7.1 Användarna ansvarar för att följa de lagar och regler som gäller för skatterättsliga konsekvenser. Som användare förpliktigas man att uppfylla dessa och hålla sig uppdaterad vid eventuella förändringar. <br/>
        <br/>
        8. Arbetsrättsliga förhållanden <br/>
        &nbsp;&nbsp; 8.1 Svippo tillhandahåller endast plattformen för att uppdragsgivare och uppdragstagare kan få kontakt. Svippo ansvarar inte för innehåll i annonser eller för utfört uppdrag. Användare av tjänsten kan aldrig hålla Svippo ansvarig för ekonomisk förlust, annan skada eller övriga brister som uppkommit i samband med användande av Svippo. <br/>
        <br/>
        9. Betalning <br/>
        &nbsp;&nbsp; 9.1 När uppdragsgivare och uppdragstagare har kommit överens om att uppdraget ska utföras, ansvarar uppdragsgivaren för att betala den överenskomna summan direkt till uppdragstagaren. För att säkerställa att alla betalningar sker korrekt och i enlighet med gällande skatteregler, måste privatpersoner som utför tjänster använda sig av en fakturera-utan-företagstjänst. Svippo tillhandahåller endast plattformen för att underlätta kontakten mellan uppdragsgivare och uppdragstagare och är inte inblandad i fakturering eller betalning. <br/>
        &nbsp;&nbsp; 9.2 Privatpersoner som använder en fakturera-utan-företagstjänst får den överenskomna summan efter att avgifter har dragits av för skatt och tjänsteleverantörens avgifter. Vanligtvis inkluderar dessa avgifter: <br/>
        &nbsp;&nbsp;&nbsp;&nbsp; Cirka 44% i skatt. <br/>
        &nbsp;&nbsp;&nbsp;&nbsp; 2-4% beroende på betalsätt. <br/>
        &nbsp;&nbsp;&nbsp;&nbsp; 2-10% beroende på vilken fakturera-utan-företagstjänst som används. <br/>
        &nbsp;&nbsp; 9.3 Vilka betalsätt finns? <br/>
        &nbsp;&nbsp;&nbsp;&nbsp; Cool Company: Avgift 5.98%, bra support och försäkringar. <br/>
        &nbsp;&nbsp;&nbsp;&nbsp; Frilans Finans: Avgift cirka 6%, populär och pålitlig. <br/>
        &nbsp;&nbsp;&nbsp;&nbsp; Pay2me: Låga avgifter och snabb service. <br/>
        &nbsp;&nbsp;&nbsp;&nbsp; Bapelsin: Enkel att använda, konkurrenskraftiga priser. <br/>
        &nbsp;&nbsp;&nbsp;&nbsp; Gigger: Bra för små uppdrag, enkel registrering. <br/>
        <br/>
        10. Försäkring <br/>
        &nbsp;&nbsp; Privatpersoner som använder Svippo för att utföra tjänster måste vända sig till sin valda fakturera-utan-företagstjänst för frågor och detaljer om försäkring. Dessa tjänster erbjuder vanligtvis försäkringsskydd som täcker sjukdom, olycksfall och ansvar under uppdragets gång. <br/>
        &nbsp;&nbsp; Företag som använder Svippo för att anlita eller utföra tjänster ansvarar för att använda sina egna företagsförsäkringar. Svippo tillhandahåller endast plattformen och är inte ansvarig för försäkringsskydd. <br/>
        <br/>
        11. Tvist <br/>
        &nbsp;&nbsp; 11.1 Vid tvist angående uppdragets utförande eller annat som hör till tjänsten ska användarna i första hand föra en dialog sinsemellan för att lösa situationen på ett konstruktivt sätt. <br/>
        &nbsp;&nbsp; 11.2 Användarna har alltid rätt att låta allmän domstol avgöra tvisten. Svensk lag tillämpas. <br/>
        <br/>
        Dessa villkor är uppdaterade den 07-12 2024.
      </Typography> {/* TODO:!!! hämta db */}
      <div>
        <Field name="privacy">
          {({ field }: FieldProps) => (
            <Checkbox
              className="large"
              label="Jag godkänner villkoren"
              {...field}
              id="privacy"
              checked={field.value ? true : false}
            />
          )}
        </Field>

        <ErrorMessage name="privacy">
          {(errorMessage: string) => (
            <div className={styleFromFormField.error}>
              <img src="/static/media/icon-error.9793803858ddef6d644ee645640bde7e.svg" alt="" />
              {errorMessage}
            </div>
          )}
        </ErrorMessage>
      </div>
    </Card>
  );
};
Conditions.title = 'Godkänn villkor';
Conditions.initialValues = {
  privacy: false,
};
Conditions.validationSchema = Yup.object({
  privacy: Yup.bool().required('Måste godkänna villkoren').isTrue().oneOf([true], 'Måste godkänna villkoren'),
});
export default Conditions;
