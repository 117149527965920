import { AdType } from 'constants/ad-type.enum';
import { FormikValues } from 'formik';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MultiStep from '../../components/forms/multi-step/multi-step.component';
import { Main } from '../../layouts/main/main.component';
import { createAd } from '../../services/ads.service';
import { useUserStore } from '../../stores';
import AdAddImg from './components/ad-addimg/ad-addimg.component';
import AdConfirm from './components/ad-confirm/ad-confirm.component';
import AdInfo from './components/ad-info/ad-info.component';
import AdPrice from './components/ad-price/ad-price.component';
import { CreateAdType } from './components/ad-type/ad-type.component';

export const CreateAd: React.FC = () => {
  const [step, setStep] = useState<number>(0);
  const [adType, setAdType] = useState<AdType | null>(null);

  const handleNext = (type: AdType) => {
    setAdType(type);
    setStep(1);
  };

  const params = useLocation();

  const initialType = params?.state?.type || '';

  CreateAdType.initialValues = { type: initialType };
  CreateAdType.defaultProps = { next: handleNext };
  AdPrice.title = adType === AdType.Service ? 'Sätt önskat pris' : 'Sätt önskad budget';
  AdPrice.label = adType === AdType.Service ? 'Pris' : 'Budget';
  AdInfo.title = `Information om din ${adType === AdType.Service ? 'tjänst' : 'förfrågan'}`;

  useEffect(() => {
    if (params?.state?.type) {
      setAdType(params.state.type);
      setStep(1);
    }
  }, [params?.state?.type]);

  const steps = [CreateAdType, AdInfo, AdAddImg, AdPrice, AdConfirm];
  const [setUser, user] = useUserStore((state) => [state.setUser, state.user]);

  const navigate = useNavigate();

  const initialValues = steps.reduce(
    (values, step) => ({
      ...values,
      ...step.initialValues,
    }),
    {}
  );

  const submitForm = async (values: FormikValues) => {
    navigate('/loading');
    await new Promise((resolve) => setTimeout(resolve, 1000));
    try {
      const newAd = await createAd(values);
      if (user && newAd) {
        const newAds = [...user.ads];
        newAds.push(newAd);
        newAds.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
        setUser({
          ...user,
          ads: newAds,
        });
      }
      navigate(`/ads/${newAd.id}`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Main>
      <MultiStep
        steps={steps}
        initialValues={initialValues}
        onSubmit={submitForm}
        step={step}
        onChangeStep={setStep}
        disableForwardOnFirstStep={true}
      />
    </Main>
  );
};
