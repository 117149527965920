import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import serviceSrc from 'assets/illustrations/buy-services.png';
import tjansterSrc from 'assets/illustrations/tjanster.png';
import classNames from 'classnames';
import { Card } from 'components/card/card.component';
import { Column, Grid } from 'components/grid/grid.component';
import { SvipperBox } from 'components/svipperbox/svipperbox.component';
import { AdType } from 'constants/ad-type.enum';
import { useFormikContext } from 'formik';
import { StepComponent } from 'interfaces/step-component.interface';
import { useUserStore } from 'stores';
import * as Yup from 'yup';
import style from './ad-type.module.scss';

const CreateAdType: StepComponent<{ next: (type: AdType) => void }> = ({ next }) => {
  const user = useUserStore((state) => state.user);

  const { values, setFieldValue } = useFormikContext<{ type: AdType }>();
  const adType = values.type;

  const handleSelect = (value: AdType) => {
    setFieldValue('type', value);
    next(value);
  };

  return (
    <Grid>
      <Column span={[6, 6, 12, 4]}>
        <Card
          role="button"
          flexDirection="row"
          className={classNames(style.box, style.request, { [style.checked]: adType === AdType.Request })}
          onClick={() => handleSelect(AdType.Request)}
        >
          <img className={style.img} alt="" src={serviceSrc} />
          <div>
            <span className="heading-large text-blue-dark">Förfrågan!</span> <br />
            <p className={style.text}>
              Hittar du inte det du söker? Skapa en förfrågan och låt säljare kontakta dig istället
            </p>
          </div>

          <div className={style.circle}>
            <NavigateNextOutlinedIcon color="warning" fontSize="large" />
          </div>
        </Card>
      </Column>
      {!user?.svipper ? (
        <Column span={[6, 6, 12, 4]}>
          <Card flexDirection="row" className={`${style.box} ${style.svipperBox}`}>
            <SvipperBox />
          </Card>
        </Column>
      ) : (
        <Column span={[6, 6, 12, 4]}>
          <Card
            role="button"
            flexDirection="row"
            className={classNames(style.box, style.service, { [style.checked]: adType === AdType.Service })}
            onClick={() => handleSelect(AdType.Service)}
          >
            <img className={style.img} alt="Förfrågan!" src={tjansterSrc} />
            <div>
              <span className="heading-large text-blue-dark">Tjänst!</span> <br />
              <p className={style.text}>Grattis! Du är en svippare. Annonsera ut en tjänst du erbjuder</p>
            </div>
            <div className={style.blueCircle}>
              <NavigateNextOutlinedIcon color="primary" fontSize="large" />
            </div>
          </Card>
        </Column>
      )}
    </Grid>
  );
};

CreateAdType.title = 'Välj annonstyp';
CreateAdType.label = 'Annonstyp';

CreateAdType.initialValues = {
  type: '',
};

CreateAdType.validationSchema = Yup.object().shape({
  type: Yup.string().oneOf(Object.values(AdType)).required(),
});

export { CreateAdType };
