import CheckRounded from '@mui/icons-material/CheckRounded';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import { Form, Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import React, { useEffect, useState } from 'react';
import { StepComponent } from 'interfaces/step-component.interface';
import { Column, Grid } from 'components/grid/grid.component';
import style from './multi-step.module.scss';
import { Stepper } from 'components/stepper/stepper.component';
import { useMediaQuery } from '@react-hookz/web';
import themeVars from 'styles/theme.module.scss';
import classNames from 'classnames';

type MultiStepProps = {
  steps: StepComponent[];
  initialValues: Record<string, any>;
  onSubmit: (values: FormikValues) => Promise<void>;
  step?: number;
  lastStepText?: string;
  onChangeStep?: (step: number) => void;
  disableForwardOnFirstStep?: boolean;
};

const MultiStep: React.FC<MultiStepProps> = ({
  steps,
  initialValues,
  onSubmit,
  step,
  lastStepText,
  disableForwardOnFirstStep = false,
}) => {
  const isXsScreen = useMediaQuery(`screen and (max-width:${themeVars['breakpoint-xs']})`);
  const isSScreen = useMediaQuery(`screen and (max-width:${themeVars['breakpoint-s']})`);
  const defaultLastStepText = isXsScreen ? 'Publicera' : 'Godkänn och publicera';

  const actualLastStepText = lastStepText || defaultLastStepText;

  const [activeStep, setActiveStep] = useState(step || 0);
  const [visited, setVisited] = useState<number[]>([0]);

  const CurrentStep = steps[activeStep];
  const { validationSchema } = CurrentStep;

  useEffect(() => {
    if (typeof step === 'number' && step !== activeStep) {
      setVisited([...visited, step]);
      setActiveStep(step);
    }
    //eslint-disable-next-line
  }, [step]);

  const isLastStep = () => activeStep === steps.length - 1;

  const handleNext = () => {
    setVisited([...visited, activeStep + 1]);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async (values: FormikValues, formikHelpers: FormikHelpers<FormikValues>) => {
    const errors = await formikHelpers.validateForm(values);
    const isValid = Object.keys(errors).length === 0;
    if (isLastStep()) {
      await onSubmit(values);
    } else {
      if (isValid) {
        handleNext();
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
      validateOnBlur={true}
      validateOnChange={false}
    >
      {(formikProps: FormikProps<FormikValues>) => {
        const { handleSubmit, errors } = formikProps;

        return (
          <Grid>
            {!isSScreen && (
              <Column span={12}>
                <div className={style.container}>
                  <section>
                    <Stepper
                      steps={steps.map((step) => step.label || step.title)}
                      active={activeStep}
                      onClick={setActiveStep}
                      clickable={!errors || Object.keys(errors).length === 0 ? visited : undefined}
                    />
                  </section>
                </div>
              </Column>
            )}

            <Column span={12}>
              <Form className={style.stepcontainer}>
                <header className={style.header}>
                  <h1 className="heading-standard">{steps[activeStep].title}</h1>
                </header>

                <CurrentStep />

                <div className={style.nextstep}>
                  <div>
                    {activeStep !== 0 && (
                      <button
                        type="button"
                        className={classNames('outlined darkblue large iconLeft', isXsScreen && 'bullet')}
                        onClick={handleBack}
                      >
                        <NavigateBeforeIcon fontSize="large" />
                        {!isXsScreen && 'Gå tillbaka'}
                      </button>
                    )}
                  </div>

                  {isLastStep() ? (
                    <button type="button" className="green large iconRight" onClick={() => handleSubmit()}>
                      {actualLastStepText}
                      <CheckRounded />
                    </button>
                  ) : disableForwardOnFirstStep && activeStep === 0 ? null : (
                    <button
                      type="button"
                      className="primary large iconRight"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Gå vidare
                      <NavigateNextOutlinedIcon fontSize="large" />
                    </button>
                  )}
                </div>
              </Form>
            </Column>
          </Grid>
        );
      }}
    </Formik>
  );
};

export default MultiStep;
