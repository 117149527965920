import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { useFormikContext } from 'formik';
import { StepComponent } from 'interfaces/step-component.interface';
import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
import { isValidFile } from '../../../../utils/fileValidation';
import { getFileUrl } from '../../../../utils/get-local-url.util';
import style from './ad-addimg.module.scss';
import { AdImageModal } from './components/ad-addimg-modal.component';
import theme from 'styles/theme.module.scss';
import { useMediaQuery } from '@react-hookz/web';
interface AdAddImgProps {
  images: File[];
}

const AdAddImg: StepComponent = () => {
  const [selectedImgIndex, setSelectedImgIndex] = useState<number | null>(null);

  const { setFieldValue, values } = useFormikContext<AdAddImgProps>();
  const isMobile = useMediaQuery(`screen and (max-width:${theme['breakpoint-s']})`);

  const images = values.images;

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleImgChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      for (let index = 0; index < e.target.files.length && index + images.length < 3; index++) {
        const imageIndex = index + images.length;
        setFieldValue(`images[${imageIndex}]`, e.target.files[index]);
      }
    }
  };

  const handleModalClose = () => {
    setSelectedImgIndex(null);
  };

  const handleDelete = (imgIndex: number) => {
    setFieldValue(
      'images',
      images.filter((_: File, index: number) => index !== imgIndex)
    );
    setSelectedImgIndex(null);
  };

  const handleNext = () => {
    setSelectedImgIndex((prev) => (prev === null ? 0 : prev === images.length - 1 ? 0 : prev + 1));
  };

  const handlePrevious = () => {
    setSelectedImgIndex((prev) => (prev === null ? 0 : prev === 0 ? images.length - 1 : prev - 1));
  };

  return (
    <div>
      <section className={style.imgboxes}>
        {images &&
          images.map((img: File, index: number) => (
            <div
              key={`ad-img-${index}`}
              className={style.uploadedimgbox}
              onClick={() => {
                isMobile && setSelectedImgIndex(index);
              }}
            >
              {!isMobile && (
                <button
                  className="bullet outlined red"
                  type="button"
                  onClick={() => {
                    handleDelete(index);
                  }}
                >
                  <DeleteIcon />
                </button>
              )}
              <img src={getFileUrl(img)} alt={`Upload ${index}`} className={style.cameraImgLarge} />
            </div>
          ))}
        {(!images || images.length < 3) && (
          <label className={style.addimgbutton} htmlFor="uploadImage">
            <input
              type="file"
              id="uploadImage"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={handleImgChange}
              multiple
              ref={fileInputRef}
            />
            <div>
              <AddAPhotoOutlinedIcon />
            </div>
          </label>
        )}
      </section>

      {selectedImgIndex !== null && (
        <div className={style.modalWrapper}>
          <AdImageModal
            index={selectedImgIndex}
            src={images[selectedImgIndex]}
            onClose={handleModalClose}
            onDelete={handleDelete}
            onNext={handleNext}
            onPrevious={handlePrevious}
          />
        </div>
      )}
    </div>
  );
};

AdAddImg.title = 'Lägg till bilder';
AdAddImg.label = 'Bilder';

AdAddImg.initialValues = {
  images: [],
};

AdAddImg.validationSchema = Yup.object().shape({
  images: Yup.array()
    .of(
      Yup.mixed()
        .test(
          'is-valid-type',
          'Felaktig filtyp',
          (file: any) => file && file.name && isValidFile(file.name.toLowerCase(), 'image')
        )
        .test('file-size', 'Bilden är för stor', (file: any) => file.size <= 10485760) // 10 MB max size (1024*1024*10)
    )
    .test('number_of_images', 'För många bilder', (images) => !images || images.length <= 3),
});

export default AdAddImg;
