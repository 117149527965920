import { ReactNode } from 'react';
import GradientBackground from '../backgrounds/gradient-background/gradient-background.component';
import style from './main.module.scss';
import { Header } from 'components/header';
import { Footer } from 'components/footer';

interface MainProps {
  children?: ReactNode;
  backgroundComp?: React.FC<{ children: ReactNode }>;
}

export const Main: React.FC<MainProps> = ({ children, backgroundComp }) => {
  const Background = backgroundComp ? backgroundComp : GradientBackground;
  return (
    <>
      <Background>
        <Header />
        <main className={style.main}>{children}</main>
        <Footer />
      </Background>
    </>
  );
};
